<template>
  <div class="container-center-horizontal">
    <div class="index-1920 screen">
      <div class="overlap-group7">
        <img class="algt-lab-english-tit" :src="overlapGroup7" alt="overlapGroup7" />
        <div class="rectangle-11"></div>
        <left-nav />
        <el-input v-model="rNumber" :placeholder="reportNumber" @keyup.enter.native="showCertificateOld"></el-input>
        <info v-bind="globalData" />
      </div>
      <div class="overlap-group3">
        <div class="flex-col">
          <div class="about-algt plusjakartasans-bold-mine-shaft-48px">{{ aboutAlgt }}</div>
          <p class="the-gemstone-market heebo-normal-mine-shaft-16px">{{ theGemstoneMarket }}</p>
          <div class="more-details">
            <router-link to="/l/about" style="visited: {text-align:center; color:#2a2f88;}">{{ moreDetails }}</router-link>
          </div>
        </div>
        <img class="rectangle-10" :src="rectangle" alt="Rectangle 10" />
      </div>
      <div class="overlap-group6">
        <img class="algt-lab-english-tit-1" :src="overlapGroup1"
          alt="ALGT lab english titles 00_00_50-00_00_53.mp4_20231111_182512 1" />
        <div class="rectangle-50"></div>
        <div class="founder plusjakartasans-normal-white-20px">{{ founder1 }}</div>
        <div class="founder-1 plusjakartasans-normal-white-20px">{{ founder2 }}</div>
        <p class="igi-gg-hrd-gg-acam-gg-feeg-eg plusjakartasans-normal-white-20px">{{ theCaratColorCl1 }}</p>
        <p class="m-sc-igi-gg-acam-gg-feeg-eg plusjakartasans-normal-white-20px">{{ theCaratColorCl2 }}</p>
        <img class="mask-group" :src="maskGroup1" alt="Mask group" />
        <div class="segers-marc plusjakartasans-bold-white-36px">{{ name1 }}</div>
        <img class="mask-group-1" :src="maskGroup2" alt="Mask group" />
        <div class="mingyue-yang plusjakartasans-bold-white-36px">{{ text6 }}</div>
      </div>
      <h1 class="title">{{ whyChooseAlgt }}</h1>
      <div class="overlap-group-container">
        <div class="overlap-group">
          <img class="union" src="img/union-4.svg" alt="Union" />
          <div class="flex-container-802913 heebo-normal-black-28px">
            <div class="text">
              <span class="heebo-normal-black-28px" v-html="highlyQualifiedProfessionals"></span>
            </div>
          </div>
        </div>
        <div class="overlap-group16">
          <div class="group-28">
            <div class="overlap-group1">
              <img class="subtract" src="img/subtract.svg" alt="Subtract" />
              <img class="subtract-1" src="img/subtract-1.svg" alt="Subtract" />
              <img class="subtract-2" src="img/subtract-2.svg" alt="Subtract" />
              <img class="subtract-3" src="img/subtract-3.svg" alt="Subtract" />
              <img class="vector-1" src="img/vector-1-1.svg" alt="Vector 1" />
              <img class="vector-2" src="img/vector-2-1.svg" alt="Vector 2" />
            </div>
            <img class="rectangle-121" src="img/rectangle-121.svg" alt="Rectangle 121" />
          </div>
          <div class="latest-technology heebo-normal-black-28px">{{ latestTechnology }}</div>
        </div>
        <div class="overlap-group-1">
          <img class="union-1" src="img/union-17-2.svg" alt="Union" />
          <div class="total-transparency heebo-normal-black-28px">{{ totalTransparency }}</div>
        </div>
        <div class="overlap-group-1">
          <img class="union-2" src="img/union-16.svg" alt="Union" />
          <div class="x5-star-services heebo-normal-black-28px">{{ x5StarServices }}</div>
        </div>
      </div>
      <div class="overlap-group-container-1">
        <div class="overlap-group">
          <img class="union-3" src="img/union-2.svg" alt="Union" />
          <div class="fast-turnaround-time heebo-normal-black-28px">{{ fastTurnaroundTime }}</div>
        </div>
        <div class="overlap-group13">
          <img class="group-29" src="img/group-29-2@2x.png" alt="Group 29" />
          <div class="competitive-prices heebo-normal-black-28px">{{ competitivePrices }}</div>
        </div>
        <div class="overlap-group5">
          <img class="union-4" src="img/union-15-2.svg" alt="Union" />
          <p class="guaranteed-peace-of heebo-normal-black-28px">{{ guaranteedPeaceOf }}</p>
        </div>
      </div>
      <div class="overlap-group2">
        <div class="our-reports plusjakartasans-bold-mine-shaft-60px">{{ title }}</div>
        <div class="flex-row">
          <div class="flex-col-1">
            <div class="name plusjakartasans-bold-mine-shaft-48px">
              <router-link to="/l/DiamondReport" style="visited: {text-align:center; color:#333;}">
                {{ name2 }}
              </router-link>
            </div>
            <p class="the-carat-color-cl heebo-normal-mine-shaft-16px">{{ theCaratColorCl3 }}</p>
          </div>
          <div class="overlap-group18">
            <div class="rectangle-5"></div>
            <img class="rectangle-3" :src="overlapGroup2" alt="Rectangle 35" />
          </div>
        </div>
      </div>
      <div class="overlap-group11">
        <div class="overlap-group20">
          <div class="rectangle-5"></div>
          <img class="rectangle-3" :src="rectangle36" alt="Rectangle 36" />
        </div>
        <div class="flex-col-2">
          <div class="gemstone-report plusjakartasans-bold-mine-shaft-48px">
            <router-link to="/l/GemstoneReport" style="visited: {text-align:center; color:#333;}">
              {{ gemstoneReport }}
            </router-link>
          </div>
          <p class="the-certificate-iden heebo-normal-mine-shaft-16px">{{ theCertificateIden1 }}</p>
        </div>
      </div>
      <div class="overlap-group12">
        <div class="flex-col-3">
          <div class="name-1 plusjakartasans-bold-mine-shaft-48px">
            <router-link to="/l/PearlReport" style="visited: {text-align:center; color:#333;}">
              {{ name3 }}
            </router-link>
          </div>
          <p class="the-carat-color-cl-1 heebo-normal-mine-shaft-16px">{{ theCaratColorCl4 }}</p>
        </div>
        <div class="overlap-group19">
          <div class="rectangle-53"></div>
          <img class="rectangle-38" :src="rectangle38" alt="Rectangle 38" />
        </div>
      </div>
      <div class="overlap-group9">
        <div class="overlap-group21">
          <div class="rectangle-54"></div>
          <img class="rectangle-37" :src="rectangle37" alt="Rectangle 37" />
        </div>
        <div class="laboratory-gr-container">
          <div class="laboratory-grown-diamond-report plusjakartasans-bold-mine-shaft-48px">
            <router-link to="/l/OtherGemstoneReport" style="visited: {text-align:center; color:#333;}">
              {{ labGrownDiamondReport }}
            </router-link>
          </div>
          <p class="alg-ts-laboratory-gr heebo-normal-mine-shaft-16px">{{ theCertificateIden2 }}</p>
        </div>
      </div>
      <div class="overlap-group15">
        <div class="rectangle-17"></div>
        <div class="premium-report plusjakartasans-bold-mine-shaft-48px">
          <router-link to="/l/PremiumReport" style="visited: {text-align:center; color:#333;}">
            {{ premiumReport }}
          </router-link>
        </div>
        <p class="encased-in-a-lush-tr heebo-normal-mine-shaft-16px">{{ encasedInALushTr }}</p>
        <img class="rectangle-44" :src="rectangle44" alt="Rectangle 44" />
      </div>
      <div class="service-container">
        <div class="service plusjakartasans-bold-mine-shaft-60px">{{ service }}</div>
        <div class="service-1">
          <div class="frame">
            <img class="lase-inscription-1" :src="laseInscription1" alt="lase inscription 1" />
            <div class="lase-inscription plusjakartasans-bold-mine-shaft-36px">{{ laseInscription }}</div>
          </div>
          <div class="frame">
            <img class="quick-check1-1" :src="quickCheck1" alt="Quick Check(1) 1" />
            <div class="quick-check plusjakartasans-bold-mine-shaft-36px">{{ quickCheck }}</div>
          </div>
          <div class="frame">
            <img class="seal-1" :src="seal1" alt="Seal 1" />
            <div class="place plusjakartasans-bold-mine-shaft-36px">{{ seal }}</div>
          </div>
        </div>
      </div>
      <foot v-bind="globalData" />
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/Navbar";
import Foot from "./components/Foot1920";
import Info from "./components/Info";

import 'element-ui/lib/theme-chalk/index.css';
export default {
  name: "Home1920",
  components: {
    LeftNav,
    Foot,
    Info,
  },
  data() {
    return {
      rNumber: '',
      rules: {
        rNumber: [{
          type: "number",
          required: true,
          message: "Must be a number",
          trigger: "blur"
        }],
      }
    }
  },
  methods: {
    showCertificateOld() {
      console.log(this.rNumber)
      // https://lab.algtlabs.com/stones/cert?rn=020390110&t=b&l=e&f=api
      window.open('https://lab.algtlabs.com/stones/cert?rn=' + this.rNumber + '&t=b&l=e&f=api', '_blank')
    },
    showCertificate() {
      console.log(this.rNumber)
      // let region = parseInt(this.rNumber.substring(0, 1))
      // if (region % 2 == 0) { // 偶数代表中国
      if (this.rNumber.trim().length == 9) { // 9位代表中国
        this.$axios.get('http://admin.algtlabs.com.cn/diamond/system/report/v',
          {
            params: { 'number': this.rNumber }
          }
        ).then(res => {
          // console.log(res.data);
          if (res.data.code == 200) {
            let url = res.data.data;
            window.open('http://admin.algtlabs.com.cn/diamond' + url, '_blank')
          } else {
            this.$message({
              message: 'report number error',
              type: 'warning'
            });
          }
        }).catch(err => {
          // console.log(err)
          this.$message({
            message: 'report number error',
            type: 'warning'
          });
        })
      } else { // 奇数代表欧洲
        // https://lab.algtlabs.com/stones/cert?rn=020390110&t=b&l=e&f=api
        window.open('https://lab.algtlabs.com/stones/cert?rn=' + this.rNumber + '&t=b&l=e&f=api', '_blank')
      }
    }
  },
  props: [
    "rectangle44",
    "whyChooseAlgt",
    "theCaratColorCl4",
    "name3",
    "theCaratColorCl3",
    "overlapGroup2",
    "globalData",
    "overlapGroup7",
    "rectangle21",
    "reportNumber",
    "rectangle",
    "phone",
    "infoAlgtlabsCom",
    "aboutAlgt",
    "theGemstoneMarket",
    "moreDetails",
    "rectangle10",
    "overlapGroup6",
    "igiGgHrdGgAcamGgFeegEg",
    "founder1",
    "mscIgiGgAcamGgFeegEg",
    "founder2",
    "maskGroup1",
    "segersMarc",
    "maskGroup2",
    "mingyueYang",
    "title",
    "highlyQualifiedProfessionals",
    "latestTechnology",
    "totalTransparency",
    "x5StarServices",
    "fastTurnaroundTime",
    "competitivePrices",
    "guaranteedPeaceOf",
    "ourReports",
    "name1",
    "theCaratColorCl1",
    "rectangle35",
    "rectangle36",
    "gemstoneReport",
    "theCertificateIden1",
    "name2",
    "text6",
    "overlapGroup1",
    "theCaratColorCl2",
    "rectangle38",
    "rectangle37",
    "labGrownDiamondReport",
    "theCertificateIden2",
    "premiumReport",
    "encasedInALushTr",
    "laseInscription1",
    "laseInscription",
    "service",
    "quickCheck1",
    "quickCheck",
    "seal",
    "seal1",
    "place",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.index-1920
  align-items: center
  background-color: $selago-2
  display: flex
  flex-direction: column
  height: 7411px
  overflow: hidden
  position: relative
  width: 1920px

.overlap-group7
  align-self: flex-start
  height: 720px
  position: relative
  width: 2000px

.algt-lab-english-tit
  height: 720px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 1920px

.rectangle-11
  background-color: $black-2
  height: 720px
  left: 0
  position: absolute
  top: 0
  width: 1920px

.rectangle-12
  background-color: $white
  border-radius: 56px
  height: 56px
  left: 660px
  position: absolute
  top: 390px
  width: 600px

.report-number
  left: 715px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 409px
  white-space: nowrap

.logo-01
  height: 87px
  left: 810px
  position: absolute
  top: 231px
  width: 300px

.group-30
  align-items: flex-end
  display: flex
  height: 20px
  left: 1507px
  min-width: 337px
  position: absolute
  top: 49px

.vector-stroke
  height: 20px
  width: 20px

.phone
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 108px
  white-space: nowrap

.vector
  align-self: center
  height: 16px
  margin-left: 30px
  width: 20px

.infoalgtlabscom
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 135px
  white-space: nowrap

.overlap-group3
  align-items: center
  background-color: $white
  display: flex
  gap: 96px
  height: 644px
  min-width: 1920px
  padding: 0 160px

.flex-col
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-bottom: 119.0px
  min-height: 331px
  width: 574px

.about-algt,
.name,
.gemstone-report,
.name-1
  letter-spacing: 0
  line-height: normal
  min-height: 60px

.the-gemstone-market
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 20px
  min-height: 208px
  width: 574px

.more-details
  color: $brand
  font-family: $font-family-heebo
  font-size: 18px
  font-weight: 400
  letter-spacing: 0
  line-height: 27px
  margin-top: 16px
  white-space: nowrap

.rectangle-10
  height: 500px
  object-fit: cover
  width: 930px

.overlap-group6
  height: 680px
  position: relative
  width: 1928px

.algt-lab-english-tit-1
  height: 680px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 1920px

.rectangle-50
  background-color: $black-2
  height: 680px
  left: 0
  position: absolute
  top: 0
  width: 1928px

.founder
  left: 510px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 429px

.founder-1
  left: 1320px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 429px

.igi-gg-hrd-gg-acam-gg-feeg-eg
  left: 386px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 466px

.m-sc-igi-gg-acam-gg-feeg-eg
  left: 1212px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 466px

.mask-group
  height: 160px
  left: 479px
  position: absolute
  top: 193px
  width: 160px

.segers-marc
  left: 449px
  letter-spacing: 0
  line-height: normal
  position: absolute
  text-align: center
  top: 372px

.mask-group-1
  height: 160px
  left: 1289px
  position: absolute
  top: 193px
  width: 160px

.mingyue-yang
  left: 1247px
  letter-spacing: 0
  line-height: normal
  position: absolute
  text-align: center
  top: 372px

.title
  color: $black
  font-family: $font-family-plus_jakarta_sans
  font-size: 60px
  font-weight: 700
  letter-spacing: 0
  line-height: 100px
  margin-left: 1.0px
  margin-top: 79px
  min-width: 591px
  white-space: nowrap

.overlap-group-container
  align-items: flex-start
  display: flex
  gap: 20px
  height: 360px
  margin-top: 81px
  min-width: 1420px

.overlap-group
  align-items: center
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 16px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  flex-direction: column
  gap: 53px
  min-height: 360px
  padding: 33px 0
  width: 340px

.union
  height: 145px
  margin-right: 0.23px
  margin-top: 24px
  width: 184px

.flex-container-802913
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 12px
  height: 72px
  position: relative
  width: 280px

.text
  align-self: stretch
  letter-spacing: 0
  line-height: 28px
  position: relative

.overlap-group16
  align-items: center
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 16px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  flex-direction: column
  gap: 45px
  min-height: 360px
  padding: 33px 0
  width: 340px

.group-28
  align-items: center
  display: flex
  flex-direction: column
  margin-left: 1.0px
  margin-top: 24px
  min-height: 153px
  width: 145px

.overlap-group1
  height: 135px
  position: relative
  width: 145px

.subtract
  height: 62px
  left: 0
  position: absolute
  top: 52px
  width: 62px

.subtract-1
  height: 43px
  left: 40px
  position: absolute
  top: 92px
  width: 48px

.subtract-2
  height: 58px
  left: 15px
  position: absolute
  top: 9px
  width: 63px

.subtract-3
  height: 35px
  left: 73px
  position: absolute
  top: 7px
  width: 45px

.vector-1
  height: 16px
  left: 108px
  position: absolute
  top: 0
  width: 37px

.vector-2
  height: 16px
  left: 108px
  position: absolute
  top: 33px
  width: 37px

.rectangle-121
  height: 18px
  margin-right: 18.24px
  width: 83px

.latest-technology,
.total-transparency,
.x5-star-services,
.fast-turnaround-time,
.competitive-prices
  letter-spacing: 0
  line-height: 28px
  min-height: 72px
  width: 280px

.overlap-group-1
  align-items: center
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 16px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  flex-direction: column
  gap: 48px
  min-height: 360px
  padding: 33px 0
  width: 340px

.union-1
  height: 155px
  margin-right: 0.86px
  margin-top: 19px
  width: 155px

.union-2
  height: 154px
  margin-left: 0.26px
  margin-top: 20px
  width: 166px

.overlap-group-container-1
  align-items: flex-start
  display: flex
  gap: 20px
  height: 360px
  margin-top: 41px
  min-width: 1060px

.union-3
  height: 150px
  margin-right: 0.79px
  margin-top: 19px
  width: 115px

.overlap-group13
  align-items: center
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 16px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  flex-direction: column
  gap: 50px
  min-height: 360px
  padding: 33px 0
  width: 340px

.group-29
  height: 155px
  margin-right: 0.43px
  margin-top: 17px
  width: 154px

.overlap-group5
  align-items: center
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 16px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  flex-direction: column
  gap: 24px
  min-height: 360px
  padding: 20px 0
  width: 340px

.union-4
  height: 143px
  margin-left: 0.51px
  margin-top: 38px
  margin-bottom: 30px
  width: 209px

.guaranteed-peace-of
  letter-spacing: 0
  line-height: 28px
  min-height: 85px
  width: 280px

.overlap-group2
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  gap: 61px
  margin-top: 80px
  min-height: 720px
  padding: 50px 280px
  width: 1920px

.our-reports
  align-self: center
  letter-spacing: 0
  line-height: 100px
  margin-left: 19.0px
  margin-top: 29px
  min-width: 423px
  white-space: nowrap

.flex-row
  align-items: center
  display: flex
  gap: 76px
  min-width: 1306px

.flex-col-1
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 30px
  margin-bottom: 9.0px
  min-height: 143px
  width: 610px

.the-carat-color-cl
  letter-spacing: 0
  line-height: 24px
  min-height: 53px
  width: 610px

.overlap-group18
  height: 430px
  position: relative
  width: 620px

.rectangle-5
  background-color: $brand
  height: 420px
  left: 10px
  opacity: 0.55
  position: absolute
  top: 10px
  width: 610px

.rectangle-3
  height: 420px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 610px

.overlap-group11
  align-items: center
  background-color: $selago
  display: flex
  gap: 80px
  height: 540px
  justify-content: flex-end
  min-width: 1920px
  padding: 50px 230px

.overlap-group20
  align-self: flex-end
  height: 430px
  position: relative
  width: 620px

.flex-col-2
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 18px
  margin-top: 77.0px
  min-height: 119px
  width: 710px

.the-certificate-iden
  letter-spacing: 0
  line-height: 24px
  min-height: 41px
  width: 710px

.overlap-group12
  align-items: center
  background-color: $white
  display: flex
  gap: 60px
  height: 530px
  min-width: 1920px
  padding: 50px 280px

.flex-col-3
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 12px
  margin-top: 73.0px
  min-height: 125px
  width: 656px

.the-carat-color-cl-1
  letter-spacing: 0
  line-height: 24px
  min-height: 53px
  width: 656px

.overlap-group19
  align-self: flex-end
  height: 420px
  position: relative
  width: 630px

.rectangle-53
  background-color: $brand
  height: 410px
  left: 10px
  opacity: 0.55
  position: absolute
  top: 10px
  width: 620px

.rectangle-38
  height: 410px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 620px

.overlap-group9
  align-items: center
  background-color: $selago
  display: flex
  gap: 58px
  height: 540px
  justify-content: flex-end
  min-width: 1920px
  padding: 50px 230px

.overlap-group21
  align-self: flex-end
  height: 420px
  position: relative
  width: 660px

.rectangle-54
  background-color: $brand
  height: 410px
  left: 10px
  opacity: 0.55
  position: absolute
  top: 10px
  width: 650px

.rectangle-37
  height: 410px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 650px

.laboratory-gr-container
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 39px
  margin-top: 70.0px
  min-height: 212px
  width: 710px

.laboratory-grown-diamond-report
  letter-spacing: 0
  line-height: normal
  min-height: 120px
  width: 667px

.alg-ts-laboratory-gr
  letter-spacing: 0
  line-height: 24px
  min-height: 53px
  width: 710px

.overlap-group15
  height: 587px
  position: relative
  width: 1920px

.rectangle-17
  background-color: $white
  height: 527px
  left: 0
  position: absolute
  top: 0
  width: 1920px

.premium-report
  left: 280px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 191px

.encased-in-a-lush-tr
  left: 280px
  letter-spacing: 0
  line-height: 24px
  position: absolute
  top: 281px
  width: 620px

.rectangle-44
  height: 527px
  left: 996px
  position: absolute
  top: 60px
  width: 924px

.service-container
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  gap: 41px
  margin-top: 40px
  min-height: 718px
  padding: 62px 0
  width: 1920px

.service
  letter-spacing: 0
  line-height: 100px
  margin-right: 1.0px
  min-width: 255px
  white-space: nowrap

.service-1
  align-items: flex-start
  display: flex
  gap: 20px
  height: 442px
  min-width: 1600px

.frame
  align-items: center
  background-color: $selago
  border-radius: 20px
  display: flex
  flex-direction: column
  gap: 39px
  min-height: 442px
  width: 520px

.lase-inscription-1,
.quick-check1-1,
.seal-1
  height: 320px
  object-fit: cover
  width: 520px

.lase-inscription
  letter-spacing: 0
  line-height: normal
  margin-right: 1.0px
  min-height: 45px
  min-width: 329px
  text-align: center

.quick-check
  letter-spacing: 0
  line-height: normal
  margin-right: 1.0px
  min-height: 45px
  min-width: 255px
  text-align: center

.place
  letter-spacing: 0
  line-height: normal
  min-height: 45px
  min-width: 90px
  text-align: center

.el-input
  // background-color: $white
  border-radius: 48px
  height: 48px
  left: 660px
  position: absolute
  top: 390px
  width: 600px
  font-size: 20px
</style>

<style lang="sass">
.el-input__inner
  border-radius: 48px
.input__inner
  font-size: 14px
</style>
